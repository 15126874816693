import request from '@/utils/request'
/**************文化培训********************/
/**
 * 文化培训列表
 * @param {*} data
 */
export function courseList(data) {
    return request({
        url: '/v2/culture/course.index/pagelist',
        method: 'post',
        params: data
    })
}

export function courseCategoryList(data) {
    return request({
        url: 'v3/api/cms/category/list',
        method: 'post',
        params: data
    })
}
/**
 * 文化培训详情
 * @param {*} data
 */
export function courseDetail(data) {
    return request({
        url: '/v2/culture/course.index/detail',
        method: 'post',
        params: data
    })
}
/**
 * 文化培训详情评价
 * @param {*} data
 */
 export function comment(data) {
    return request({
        url: '/v2/activity/comment/average',
        method: 'post',
        params: data
    })
}