<script src="../../../../bg_admin_app_lib_official/src/main.js"></script>
<template>
  <div class="homepage-index-bg">
    <div class="homepage-index-news-bg">
      <!-- 轮播图 -->
      <div class="homepage-index-news-bg-swiper">
        <el-carousel height="7rem">
          <el-carousel-item v-for="(item, i) in swiperList" :key="i">
            <img
              @click="go_banner(item)"
              :src="item.img_url"
              alt=""
              style="width: 100%; height: 100%; cursor: pointer"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="homepage-index-news">
        <!--资讯动态-->
        <div class="homepage-index-news-title">
          <div class="homepage-index-news-title-left">
            <div class="homepage-index-news-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>
            <div
              class="homepage-index-news-title-left-title"
              style="cursor: pointer"
            >
              <div @click="goNews()">
                <img
                  style="width: 100%; height: 100%"
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/home-newsword.png"
                  alt=""
                />
              </div>
            </div>
            <div class="homepage-index-news-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_right.png"
                alt=""
              />
            </div>
          </div>
          <div class="homepage-index-news-title-right">
            <div class="news-button">
              <img
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_news_title.png"
                alt=""
                @click="goNewsLeft()"
              />
            </div>
            <div class="news-button">
              <img
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_notice_title.png"
                alt=""
                @click="goNewsRight()"
              />
            </div>
            <img
              src="https://image.bookgo.com.cn/webculture/jm/pagination/nextBtn.png"
              alt=""
            />
          </div>
        </div>
        <div class="homepage-index-news-content" v-loading="articleLoading">
          <div class="homepage-index-news-content-left">
            <ul class="homepage-index-news-content-left-ul">
              <li
                class="homepage-index-news-content-left-ul-li"
                v-for="(item, index) in articleList"
                :key="index" v-if="index>0"
              >
                <a @click="goNewsDetail(item)" v-if="item.is_link != 1">
                  <div class="homepage-index-news-content-left-ul-li-left">
                    <div
                      class="homepage-index-news-content-left-ul-li-left-day"
                    >
                      {{ item.publish_time.substring(8, 10) }}
                    </div>
                    <div
                      class="homepage-index-news-content-left-ul-li-left-date"
                    >
                      {{ item.publish_time.substring(0, 7) }}
                    </div>
                  </div>
                  <div class="homepage-index-news-content-left-ul-li-right">
                    <div
                      class="homepage-index-news-content-left-ul-li-right-title"
                    >
                      {{ item.title }}
                    </div>
                    <div
                      class="homepage-index-news-content-left-ul-li-right-des"
                    >
                      {{ item.summary }}
                    </div>
                  </div>
                </a>
                <a :href="item.jump_url" target="blank" v-else>
                  <div class="homepage-index-news-content-left-ul-li-left">
                    <div
                      class="homepage-index-news-content-left-ul-li-left-day"
                    >
                      {{ item.publish_time.substring(8, 10) }}
                    </div>
                    <div
                      class="homepage-index-news-content-left-ul-li-left-date"
                    >
                      {{ item.publish_time.substring(0, 7) }}
                    </div>
                  </div>
                  <div class="homepage-index-news-content-left-ul-li-right">
                    <div
                      class="homepage-index-news-content-left-ul-li-right-title"
                    >
                      {{ item.title }}
                    </div>
                    <div
                      class="homepage-index-news-content-left-ul-li-right-des"
                    >
                      {{ item.summary }}
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="homepage-index-news-content-right"
            v-if="articleList.length > 0"
            style="cursor: pointer"
          >
            <div
              class="homepage-index-news-content-right-banner"
              @click="goNewsDetail(articleList[0])"
            >
              <img
                style="width: 100%; height: 100%"
                :src="
                  articleList[0].cover == ''
                    ? 'https://image.bookgo.com.cn/image/defaultM.jpg'
                    : articleList[0].cover
                "
                alt=""
              />
            </div>
            <div class="homepage-index-news-content-right-bottom">
              <div
                class="homepage-index-news-content-right-bottom-title"
                @click="goNewsDetail(articleList[0])"
              >
                {{ articleList[0].title }}
              </div>
              <div class="homepage-index-news-content-right-bottom-date">
                {{ articleList[0].publish_time }}
              </div>
            </div>
          </div>
        </div>
        <!--资讯动态 end-->
      </div>
    </div>

    <!-- 群文活动 && 文化直播-->
    <div class="homepage-index-activity-bg">
      <div class="homepage-index-activity">
        <div class="homepage-index-activity-title">
          <div class="homepage-index-activity-title-left">
            <div class="homepage-index-activity-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>
            <div class="homepage-index-activity-title-left-title">
              <img
                class="homepage-index-activity-title-left-title-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_title.png"
                alt=""
                @click="left1 = 0"
              />
              <div class="homepage-index-activity-title-left-title-dot"></div>
              <img
                class="homepage-index-activity-title-left-title-sub-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_live_title.png"
                alt=""
                @click="left1 = '1.6rem'"
              />
              <img
                class="homepage-title-img-bg"
                :style="{ left: left1, transition: 'all 0.3s' }"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_title_bg.png"
              />
            </div>
            <div class="homepage-index-activity-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_right.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="homepage-index-activity-title-right"
            @click="left1 == 0 ? moreActive() : moreLive()"
          >
            更多
          </div>
        </div>
        <ul
          class="homepage-index-activity-ul"
          v-if="left1 == 0"
          v-loading="actLoading"
        >
          <li
            class="homepage-index-activity-li"
            v-for="(item, index) in activeList"
            :key="index"
            @click="goActive(item)"
          >
            <div class="homepage-index-activity-li-banner">
              <img
                style="width: 100%; height: 100%"
                :src="item.banner"
                alt=""
              />
            </div>
            <div
              class="homepage-index-activity-li-bottom"
              :title="`活动时间：${item.active_start_time} ~ ${item.active_start_time}`"
            >
              <div class="homepage-index-activity-li-bottom-title">
                {{ item.title }}
              </div>

              <div class="homepage-index-activity-li-bottom-des">
                <img
                  class="homepage-index-activity-li-bottom-icon"
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                  alt=""
                />
                <div class="homepage-index-activity-li-bottom-icon-title">
                  {{ item.active_time }}
                </div>
              </div>
              <div class="homepage-index-activity-li-bottom-des">
                <img
                  class="homepage-index-activity-li-bottom-icon"
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_address_icon.png"
                  alt=""
                />
                <div class="homepage-index-activity-li-bottom-icon-title">
                  {{ item.address }}
                </div>
              </div>
            </div>
            <img
              class="homepage-index-activity-li-selected"
              src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
              alt=""
            />
          </li>
        </ul>
        <div class="home-tab-live" v-else>
          <ul class="live-ul1">
            <li
              class="live-ul1-li"
              v-for="(item, index) in liveList"
              :key="index"
              @click="goLive(item)"
            >
              <div class="live-ul1-li-banner">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.cover"
                  alt=""
                />
              </div>
              <div class="live-ul1-li-bottom">
                <div class="live-ul1-li-bottom-title">{{ item.title }}</div>
                <div
                  class="live-ul1-li-bottom-status live-status-bg1"
                  v-if="item.status == 1"
                >
                  正在直播
                </div>
                <div
                  class="live-ul1-li-bottom-status live-status-bg2"
                  v-if="item.status == 2"
                >
                  直播预告
                </div>
                <div
                  class="live-ul1-li-bottom-status live-status-bg2"
                  v-if="item.status == 3"
                >
                  精彩回顾
                </div>
                <div class="live-ul1-li-bottom-sponsor">
                  主办单位：{{ item.sponsor }}
                </div>
                <div class="live-ul1-li-bottom-summary">
                  直播简介：{{ item.content | deleteHtmlTag }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--群文活动 && 文化直播 end-->

    <!--艺术培训 && 精品欣赏-->
    <div class="homepage-index-culture-bg">
      <div class="homepage-index-culture">
        <div class="homepage-index-culture-title">
          <div class="homepage-index-culture-title-left">
            <div class="homepage-index-culture-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>
            <div class="homepage-index-culture-title-left-title">
              <img
                class="homepage-index-culture-title-left-title-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_culture_title.png"
                alt=""
                @click="left2 = 0"
              />
              <div class="homepage-index-culture-title-left-title-dot"></div>
              <img
                class="homepage-index-culture-title-left-title-sub-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_enjoyWorks_title.png"
                alt=""
                @click="left2 = '1.6rem'"
              />
              <img
                class="homepage-title-img-bg"
                :style="{ left: left2, transition: 'all 0.3s' }"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_title_bg.png"
              />
            </div>
            <div class="homepage-index-culture-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_right.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="homepage-index-culture-title-right"
            @click="left2 == 0 ? moreCourse() : moreImage()"
            style="cursor: pointer"
          >
            更多
          </div>
        </div>
        <ul
          class="homepage-index-culture-ul"
          v-if="left2 == 0"
          v-loading="actLoading"
        >
          <li
            class="homepage-index-culture-li"
            v-for="(item, index) in courseList"
            :key="index"
          >
            <div @click="goCourse(item)">
              <div class="homepage-index-culture-li-banner">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.cover"
                  alt=""
                />
              </div>
              <div
                class="homepage-index-culture-li-bottom"
                :title="`时间：${item.start_date} ~ ${item.end_date}`"
              >
                <div class="homepage-index-culture-li-bottom-title">
                  {{ item.title }}
                </div>
                <div class="homepage-index-culture-li-bottom-des">
                  <img
                    class="homepage-index-culture-li-bottom-icon"
                    src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_train_teacher_icon.png"
                    alt=""
                  />
                  <div class="homepage-index-culture-li-bottom-icon-title">
                    {{ item.speaker }}
                  </div>
                </div>
                <div class="homepage-index-culture-li-bottom-des">
                  <img
                    class="homepage-index-culture-li-bottom-icon"
                    src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                    alt=""
                  />
                  <div class="homepage-index-culture-li-bottom-icon-title">
                    {{ item.start_date }} ~ {{ item.end_date }}
                  </div>
                </div>
              </div>
            </div>
            <img
              class="homepage-index-culture-li-selected"
              src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
              alt=""
            />
          </li>
        </ul>
        <ul class="homepage-index-culture-ul" v-else v-loading="actLoading">
          <li
            class="homepage-index-culture-li homepage-index-artistic"
            v-for="(item, index) in imageList"
            :key="index"
            @click="goImage(item)"
          >
            <div class="homepage-index-artistic-banner">
              <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
            </div>
            <div class="homepage-index-culture-li-bottom">
              <div class="homepage-index-culture-li-bottom-title">
                {{ item.title }}
              </div>
              <p class="homepage-index-culture-li-bottom-intro">
                {{ item.content | deleteHtmlTag }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--艺术培训 && 精品欣赏 end-->

    <!--非遗文化 && 网上陈列-->
    <div class="homepage-index-intangible-bg">
      <div class="homepage-index-intangible">
        <div class="homepage-index-intangible-title">
          <div class="homepage-index-intangible-title-left">
            <div class="homepage-index-intangible-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>
            <div class="homepage-index-intangible-title-left-title">
              <img
                class="homepage-index-intangible-title-left-title-img"
                src="https://image.bookgo.com.cn/webculture%2Fjm%2Fhomepage%2Fhomepage_resource_title.png"
                alt=""
                @click="left3 = 0"
              />
              <div class="homepage-index-intangible-title-left-title-dot"></div>
              <img
                class="homepage-index-intangible-title-left-title-sub-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_intangible_title.png"
                alt=""
                @click="left3 = '1.6rem'"
              />
              <img
                class="homepage-title-img-bg"
                :style="{ left: left3, transition: 'all 0.3s' }"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_title_bg.png"
              />
            </div>
            <div class="homepage-index-intangible-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_right.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="homepage-index-intangible-title-right"
            @click="left2 == 0 ? moreResource() : moreHeritage()"
            style="cursor: pointer"
          >
            更多
          </div>
        </div>
        <ul
          class="homepage-index-intangible-ul"
          v-if="left3 == 0"
          v-loading="actLoading"
          target="_blank"
        >
          <li
            class="homepage-index-intangible-li"
            v-for="(item, index) in resourceList"
            :key="index"
          >
            <div @click="goResource(item)">
              <div class="homepage-index-intangible-li-banner">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.main_pic"
                  alt=""
                />
              </div>
              <div class="homepage-index-intangible-li-bottom">
                <div class="homepage-index-intangible-li-bottom-title">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <img
              class="homepage-index-intangible-li-selected"
              src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
              alt=""
            />
          </li>
        </ul>
        <ul class="homepage-index-intangible-ul" v-else v-loading="actLoading">
          <li
            class="homepage-index-intangible-li homepage-index-artistic"
            v-for="(item, index) in heritageList"
            :key="index"
          >
            <div @click="goHeritage(item)">
              <div class="homepage-index-artistic-banner">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.main_pic"
                  alt=""
                />
              </div>
              <div class="homepage-index-intangible-li-bottom">
                <div class="homepage-index-intangible-li-bottom-title">
                  {{ item.name }}
                </div>
                <p class="homepage-index-intangible-li-bottom-intro">
                  {{ item.des | deleteHtmlTag }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--非遗文化 && 网上陈列 end-->

    <!-- 场馆预定 && 志愿服务 -->
    <div class="homepage-index-reserve-bg">
      <div class="homepage-index-reserve-bg-bgPic" v-if="left4 == 0">
        <img
          src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-reserve-bg.png"
          alt=""
        />
      </div>
      <div class="homepage-index-reserve">
        <div class="homepage-index-reserve-title">
          <div class="homepage-index-reserve-title-left">
            <div class="homepage-index-reserve-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>
            <div class="homepage-index-reserve-title-left-title">
              <img
                class="homepage-index-reserve-title-left-title-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_reserve_title.png"
                alt=""
                @click="left4 = 0"
              />
              <div class="homepage-index-reserve-title-left-title-dot"></div>
              <img
                class="homepage-index-reserve-title-left-title-sub-img"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_volunteer_title.png"
                alt=""
                @click="left4 = '1.6rem'"
              />
              <img
                class="homepage-title-img-bg"
                :style="{ left: left4, transition: 'all 0.3s' }"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_title_bg.png"
              />
            </div>
            <div class="homepage-index-reserve-title-left-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_right.png"
                alt=""
              />
            </div>
          </div>

          <div
            class="homepage-index-reserve-title-right"
            @click="left4 == 0 ? moreVenue() : moreVolunteer()"
            style="cursor: pointer"
          >
            更多
          </div>
        </div>
        <div
          class="homepage-index-reserve-content clear"
          v-if="venueList.length !== 0 && left4 == 0" v-loading="venueLoading"
        >
          <div class="homepage-index-reserve-content-left">
            <img
              :src="venueList[current].space_pic"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="homepage-index-reserve-content-right">
            <div class="homepage-index-reserve-content-right-title">
              {{ venueList[current].space_name }}
            </div>
            <ul class="homepage-index-reserve-content-right-info">
              <!-- <li v-if="venueList[current].place">
                <img
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-reserve-place-icon.png"
                  alt=""
                />
                <span>场所：</span>
                <p>{{ venueList[current].place }}</p>
              </li>
              <li>
                <img
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-reserve-clock-icon.png"
                  alt=""
                />
                <span>时间：</span>
                <div class="timeBox">
                  <p>{{ venueList[current].work_time }}</p>
                </div>
              </li> -->
              <li>
                <img
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-reserve-location-icon.png"
                  alt=""
                />
                <span>地址：</span>
                <p>{{ venueList[current].address }}</p>
              </li>
              <li>
                <img
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-reserve-phone-icon.png"
                  alt=""
                />
                <span>电话：</span>
                <p>{{ venueList[current].phone }}</p>
              </li>
            </ul>
            <div
              class="homepage-index-reserve-content-right-reserveBtn"
              @click="handleServe"
            >
              立即预定
            </div>
            <div class="homepage-index-reserve-content-right-line">
              <ul
                class="homepage-index-reserve-content-right-line-list"
                :style="{
                  'margin-left': -1.2 * num + 'rem',
                  transition: 'all,0.3s',
                }"
              >
                <li
                  v-for="(item, i) in venueList"
                  :key="i"
                  @click="
                    current = i;
                    handleClick(i);
                  "
                >
                  <div class="picbox" :class="{ activePic: current == i }">
                    <img :src="item.space_pic" alt="" />
                  </div>
                  <p>{{ item.space_name }}</p>
                </li>
                <li class="crossline"></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="voluteer-info" v-else>
          <ul class="volunteer-ul">
            <li
              class="volunteer-ul-li"
              v-for="(item, index) in volunteerList"
              :key="'volunteer' + index"
              @click="goVolunteer(item)"
            >
              <div class="volunteer-ul-li-banner">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.cover"
                  alt=""
                />
                <div class="volunteer-ul-li-banner-total">
                  {{ item.team_total }}人
                </div>
              </div>
              <div class="volunteer-ul-li-bottom">
                <div class="volunteer-ul-li-bottom-title">{{ item.name }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 场馆预定 && 志愿服务end -->
    <!-- 侧边导航 -->
    <div class="homepage-fix-enter">
      <div class="homepage-fix-enter-box">
        <ul class="homepage-fix-enter-box-ul">
          <li
            class="homepage-fix-enter-box-ul-li homepage-fix-enter-box-ul-li1"
          >
            <div class="homepage-fix-enter-box-ul-li-div">
              <img
                style="width: 100%; height: 100%"
                alt=""
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-fix-left-wxicon.png"
              />
              <div class="homepage-fix-enter-box-ul-li-div-positions">
                <img
                  style="
                    width: 2.64rem;
                    height: 2.7rem;
                    display: block;
                    margin-bottom: 0.11rem;
                  "
                  src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-fix-left-vxicon.png"
                  alt=""
                />
                <div class="homepage-fix-enter-box-ul-li-div-positions-title">
                  微信扫一扫关注公众号
                </div>
              </div>
            </div>
            微信
          </li>
          <li
            class="homepage-fix-enter-box-ul-li homepage-fix-enter-box-ul-li2"
          >
            <div class="homepage-fix-enter-box-ul-li-div">
              <img
                style="width: 100%; height: 100%"
                alt=""
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-fix-left-miniprogram.png"
              />
              <div class="homepage-fix-enter-box-ul-li-div-positions1">
                <img
                  style="
                    width: 2.64rem;
                    height: 2.7rem;
                    display: block;
                    margin: 0 auto 0.11rem;
                  "
                  :src="wxprogram_code"
                  alt=""
                />
                <div class="homepage-fix-enter-box-ul-li-div-positions-title">
                  微信扫一扫打开荆门群艺馆小程序
                </div>
              </div>
            </div>
            小程序
          </li>
          <li class="homepage-fix-enter-box-ul-li" @click="scrollTop">
            <div class="homepage-fix-enter-box-ul-li-return">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage-fix-left-totop.png"
                alt=""
              />
            </div>
            <div class="homepage-fix-enter-box-ul-li-title">回顶部</div>
          </li>
        </ul>
      </div>
    </div>
    <mydialog
      v-if="dialogVisible"
      :keyword="'扫码预定场馆'"
      :isShow="dialogVisible"
      :src="detailSrc"
      @close="dialogVisible = false"
    />
  </div>
</template>

<script>
import { getBannerPic } from "@/api/homepage/banner";
import { getActivityList } from "@/api/activity/activity";
import { courseList } from "@/api/culture/culture";
import { list as cultureList } from "@/api/culture";
import { list as articleList,recommend_article } from "@/api/article";
import { queryLiveList } from "@/api/common";
import { ResourceList } from "@/api/common";
import { getVenueMap, getSpace, getMapInfo } from "@/api/map/map.js";
import { voluteerList } from "@/api/common";
import { getStadium } from "@/api/stadium-reserve/stadium-reserve";
import ListItem from "@/views/components/listItem";
import moment from "moment";
import { genQRCode } from "@/api/common";
import mydialog from "@/components/myDialog";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { getConf, getArticleList } from "@/api/common";
import { navList } from "@/api/header/header";
import { infoList as cmsInfo, navinfo } from "@/api/header/header";
export default {
  data() {
    return {
      articleLoading: true,
      actLoading: true,
      courseLoading: true,
      cultureLoading: true,
      venueLoading: true,
      aid: process.env.VUE_APP_AID,
      article_list: [],

      chapter_list: [],
      liveList: [],
      intangible_list: [],
      intangible_active: 0,
      swiperList: [],
      left1: 0,
      left2: 0,
      left3: 0,
      left4: 0,
      dialogVisible: false,
      stadiumList: [],
      current: 0,
      num: 0,
      // 志愿服务
      rows: [],
      listQuery: {
        aid: process.env.VUE_APP_AID,
        page: 1,
        page_size: 3,
      },
      loading: true,
      content: [],
      appreciation_list: [],
      exhibit_list: [],
      detailSrc: "",
      appreciation_id: "", //精品欣赏
      exhibit_id: "", // 网上陈列
      exhibit_cat_id: "", // 网上陈列
      dynamic_id: "", // 资讯动态
      cutural_id: "", // 文化资讯
      notice_id: "", // 通知公告
      class_id: process.env.CLASS_ID,
      wxprogram_code: "",
      resource_cat_id: 0,
      intangible_cat_id: 0,
      Course_cat_id: 0,
      resource_name: "",
      intangible_name: "",
      artistic_pid: 0,
      artistic_nav_id: 0,
      artistic_name: "",
      news_cat_id: 0,
      newsid: 0,
      newsNavId: 0,
      newsName: "",
      navList: [],
      activeList: [],
      liveList: [],
      courseList: [],
      imageList: [],
      resourceList: [],
      heitageList: [],
      venueList: [],
      volunteerList: [],
      articleList: [],
      activeCid: 0,
      liveCid: 0,
      courseCid: 0,
      imageCid: 0,
      resourceCid: 0,
      heritageCid: 0,
      venueCid: 0,
      volunteerCid: 0,
      articleCid: 0,
      articleCid1: 0,
      articleCid2: 0,
    };
  },
  components: { mydialog, ListItem },
  mounted() {
    // this.getActList();
    // this.getCourseList();
    // this.getCultureOne();
    this.getNavList();
    this.getBannerList();
    // this.getActiveList();
    // this.getliveList();
    // this.getStadiumList();
    // this.getVolunteerList();
    // this.getConf();
    this.getCode();
    // this.getNavList()
  },
  watch: {
    // current(newV, oldV) {
    //   // console.log(newV, oldV);
    //   if (newV > oldV && newV >= 3) {
    //     this.num += 1;
    //   }
    //   if (newV < oldV) {
    //     this.num -= 1;
    //   }
    //   if (newV > this.stadiumList.length - 3) {
    //     this.num = this.stadiumList.length - 4;
    //   }
    // },
    // num(newV, oldV) {
    //   console.log(newV, oldV);
    //   if (newV <= 0) {
    //     this.num = 0;
    //   }
    // },
  },
  methods: {
    getNavList() {
      navList({ aid: this.aid }).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          this.navList = res.data.list;
          console.log("this.navList", this.navList);
        }
        var activeFlag = 0;
        this.navList.forEach((item) => {
          if (activeFlag == 0 && item.tpl_code == "active-default") {
            this.activeCid = item.column_id;
            activeFlag = 1;
            this.getActiveList();
          }
          if (activeFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (activeFlag == 0 && i.tpl_code == "active-default") {
                this.activeCid = i.column_id;
                activeFlag = 1;
                this.getActiveList();
              }
            });
          }
        });
        var liveFlag = 0;
        this.navList.forEach((item) => {
          if (liveFlag == 0 && item.tpl_code == "live-default") {
            this.liveCid = item.column_id;
            liveFlag = 1;
            this.getLiveList();
          }
          if (liveFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (liveFlag == 0 && i.tpl_code == "live-default") {
                this.liveCid = i.column_id;
                liveFlag = 1;
                this.getLiveList();
              }
            });
          }
        });
        var courseFlag = 0;
        this.navList.forEach((item) => {
          if (courseFlag == 0 && item.tpl_code == "course-default") {
            this.courseCid = item.column_id;
            courseFlag = 1;
            this.getCourseList();
          }
          if (courseFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (courseFlag == 0 && i.tpl_code == "course-default") {
                this.courseCid = i.column_id;
                courseFlag = 1;
                this.getCourseList();
              }
            });
          }
        });
        var imageFlag = 0;
        this.navList.forEach((item) => {
          if (imageFlag == 0 && item.tpl_code == "cmsCommon-image") {
            this.imageCid = item.column_id;
            imageFlag = 1;
            this.getImageList();
          }
          if (imageFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (imageFlag == 0 && i.tpl_code == "cmsCommon-image") {
                this.imageCid = i.column_id;
                imageFlag = 1;
                this.getImageList();
              }
            });
          }
        });
        var resourceFlag = 0;
        this.navList.forEach((item) => {
          if (resourceFlag == 0 && item.tpl_code == "resource-default") {
            this.resourceCid = item.column_id;
            resourceFlag = 1;
            this.getResourceList();
          }
          if (resourceFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (resourceFlag == 0 && i.tpl_code == "resource-default") {
                this.resourceCid = i.column_id;
                resourceFlag = 1;
                this.getResourceList();
              }
            });
          }
        });
        var heritageFlag = 0;
        this.navList.forEach((item) => {
          if (heritageFlag == 0 && item.tpl_code == "heritage-default") {
            this.heritageCid = item.column_id;
            heritageFlag = 1;
            this.getHeritageList();
          }
          if (heritageFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (heritageFlag == 0 && i.tpl_code == "heritage-default") {
                this.heritageCid = i.column_id;
                heritageFlag = 1;
                this.getHeritageList();
              }
            });
          }
        });
        var volunteerFlag = 0;
        this.navList.forEach((item) => {
          if (volunteerFlag == 0 && item.tpl_code == "volunteerTeam-default") {
            this.volunteerCid = item.column_id;
            volunteerFlag = 1;
            this.getVolunteerList();
          }
          if (volunteerFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (volunteerFlag == 0 && i.tpl_code == "volunteerTeam-default") {
                this.volunteerCid = i.column_id;
                volunteerFlag = 1;
                this.getVolunteerList();
              }
            });
          }
        });
        var venueFlag = 0;
        this.navList.forEach((item) => {
          if (venueFlag == 0 && item.tpl_code == "venue-default") {
            this.venueCid = item.column_id;
            venueFlag = 1;
            this.getVenueList();
          }
          if (venueFlag == 0 && item.children && item.children.length > 0) {
            item.children.forEach((i) => {
              if (venueFlag == 0 && i.tpl_code == "venue-default") {
                this.venueCid = i.column_id;
                venueFlag = 1;
                this.getVenueList();
              }
            });
          }
        });
        var articleFlag = 0;
        this.navList.forEach((item) => {
          if (articleFlag == 0 && item.name == "资讯动态") {
            // this.articleCid = item.column_id;
            console.log(1111111,item)
            articleFlag = 1;
            if (item.children && item.children.length > 0) {
              console.log(22222222,item.children)
              this.articleCid = item.children[0].column_id;
              console.log(333333,this.articleCid)
              item.children.forEach((i) => {
                if (i.name == "文化资讯") {
                  this.articleCid1 = i.column_id;
                }
                if (i.name == "通知公告") {
                  this.articleCid2 = i.column_id;
                }
              });
            } else {
              this.articleCid = item.column_id;
            }
             this.getArticleList();
          }
        });
      });
    },
    scrollTop() {
      document.documentElement.scrollTop = 0;
    },
    getBannerList() {
      let params = { aid: this.aid, classify: 3 };
      getBannerPic(params).then((res) => {
        console.log(res);
        this.swiperList = res.data;
        console.log(this.swiperList);
      });
    },
    // banner_跳转
    go_banner(item) {
      if (item.type == -1) {
      } else {
        if (item.column_id > 0) {
          var tpl_code = "";
          navinfo({ column_id: item.column_id }).then((res) => {
            if (res.code == 0) {
              tpl_code = res.data.tpl_code;
              if (item.content_id == 0) {
                if (res.data.pid == 0) {
                  this.$router.push(
                    `/${tpl_code}?id=${res.data.id}&cid=&column_id=${item.column_id}`
                  );
                } else {
                  this.$router.push(
                    `/${tpl_code}?id=${res.data.pid}&cid=${res.data.id}&column_id=${item.column_id}`
                  );
                }
              } else if (item.content_id > 0) {
                if (res.data.pid == 0) {
                  this.$router.push(
                    `/${tpl_code}/detail?tid=${item.content_id}&id=${res.data.id}&cid=&column_id=${item.column_id}`
                  );
                } else {
                  this.$router.push(
                    `/${tpl_code}/detail?tid=${item.content_id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${item.column_id}`
                  );
                }
              }
            }
          });
        } else {
          if (item.link) {
            window.open(item.link);
          }
        }
      }
    },
    //获取首页群文活动列表
    getActiveList() {
      getActivityList({
        aid: this.aid,
        page: 1,
        page_size: 3,
        column_id: this.activeCid,
      }).then((res) => {
        if (res.code == 0) {
          console.log(res.data.datalist);
          if (res.data.datalist.length > 3) {
            this.activeList = res.data.datalist.slice(0, 3);
          } else {
            this.activeList = res.data.datalist;
          }
        }

        this.actLoading = false;
      });
    },
    //更多群文活动
    moreActive() {
      navinfo({ column_id: this.activeCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/active-default?id=${res.data.id}&cid=&column_id=${this.activeCid}`
            );
          } else {
            this.$router.push(
              `/active-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            );
          }
        }
      });
    },
    //跳转群文活动详情
    goActive(item) {
      console.log(item);
      navinfo({ column_id: this.activeCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/active-default/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.activeCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/active-default/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.activeCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
    },
    //获取首页直播列表
    getLiveList() {
      queryLiveList({
        aid: this.aid,
        page: 1,
        page_size: 3,
        type: 0,
        column_id: this.liveCid,
      }).then((res) => {
        console.log(res);
        var list = res.data.datalist;
        var regex = /(<([^>]+)>)/gi;
        if (list.length != 0) {
          list.forEach((item) => {
            item.content = item.content.replace(regex, "");
          });
        }
        this.liveList = res.data.datalist;
        this.actLoading = false;
      });
    },
    //更多直播
    moreLive() {
      navinfo({ column_id: this.liveCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/live-default?id=${res.data.id}&cid=&column_id=${this.liveCid}`
            );
          } else {
            this.$router.push(
              `/live-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.liveCid}`
            );
          }
        }
      });
    },
    //跳转直播详情
    goLive(item) {
      navinfo({ column_id: this.liveCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/live-default/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.liveCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/live-default/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.liveCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
    },
    //获取首页艺术培训列表
    getCourseList() {
      courseList({
        aid: this.aid,
        page: 1,
        page_size: 3,
        column_id: this.courseCid,
      }).then((res) => {
        if (res.code == 0) {
          let { datalist } = res.data;
          this.courseList = datalist;
        }
        this.courseLoading = false;
      });
    },
    //更多艺术培训
    moreCourse() {
      navinfo({ column_id: this.courseCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/course-default?id=${res.data.id}&cid=&column_id=${this.courseCid}`
            );
          } else {
            this.$router.push(
              `/course-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.courseCid}`
            );
          }
        }
      });
    },
    //艺术培训详情
    goCourse(item) {
      navinfo({ column_id: this.courseCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/course-default/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.courseCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/course-default/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.courseCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
    },
    //获取首页精品欣赏列表
    getImageList() {
      articleList({
        aid: this.aid,
        app_type: 1,
        page_size: 3,
        column_id: this.imageCid,
      }).then((res) => {
        if (res.code == 0) {
          this.imageList = res.data.list;
        }
        this.actLoading = false;
      });
    },
    //更多精品欣赏
    moreImage() {
      navinfo({ column_id: this.imageCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/cmsCommon-image?id=${res.data.id}&cid=&column_id=${this.imageCid}`
            );
          } else {
            this.$router.push(
              `/cmsCommon-image?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.imageCid}`
            );
          }
        }
      });
    },
    //精品欣赏详情
    goImage(item) {
      if(item.is_link==1){
          window.open(item.jump_url, "_blank");
      }else{
        navinfo({ column_id: this.imageCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/cmsCommon-image/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.imageCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/cmsCommon-image/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.imageCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
      }

    },
    //获取首页数字资源列表
    getResourceList() {
      ResourceList({
        aid: this.aid,
        limit: 3,
        column_id: this.resourceCid,
      }).then((res) => {
        if (res.code == 0) {
          this.resourceList = res.data.datalist;
        }
        this.actLoading = false;
      });
    },
    //更多数字资源
    moreResource() {
      navinfo({ column_id: this.resourceCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/resource-default?id=${res.data.id}&cid=&column_id=${this.resourceCid}`
            );
          } else {
            this.$router.push(
              `/resource-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.resourceCid}`
            );
          }
        }
      });
    },
    //跳转数字资源详情
    goResource(item) {
      navinfo({ column_id: this.resourceCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/resource-default/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.resourceCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/resource-default/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.resourceCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
    },
    //获取首页非遗保护列表
    getHeritageList() {
      ResourceList({
        aid: this.aid,
        limit: 3,
        column_id: this.heritageCid,
      }).then((res) => {
        if (res.code == 0) {
          this.heritageList = res.data.datalist;
        }
        this.actLoading = false;
      });
    },
    //更多非遗保护
    moreHeritage() {
      navinfo({ column_id: this.heritageCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/heritage-default?id=${res.data.id}&cid=&column_id=${this.heritageCid}`
            );
          } else {
            this.$router.push(
              `/heritage-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.heritageCid}`
            );
          }
        }
      });
    },
    //跳转非遗保护详情
    goHeritage(item) {
      navinfo({ column_id: this.heritageCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/heritage-default/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.heritageCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/heritage-default/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.heritageCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
    },
    //获取首页场馆信息
    getVenueList() {
      let params = {
        aid: process.env.VUE_APP_AID,
        column_id: this.venueCid,
        venue_id: 0,
      };
      getSpace(params).then((res) => {
        if (res.code == 0) {
          var venueList = res.data.datalist;
          venueList.forEach((item,index) => {
            getMapInfo({
              id: item.venue_id,
              aid: process.env.VUE_APP_AID,
            }).then((res) => {
              if (res.code == 0) {
                item["phone"] = res.data.phone;
                item["address"] = res.data.address_des;
              }
              if(index==venueList.length-1){
                this.venueList = venueList;
                this.venueLoading = false;
              }
            });
          });

          // this.venueList = venueList;
          // console.log('this.venueList',this.venueList)
        }
      });
    },
    //更多场馆
    moreVenue() {
      navinfo({ column_id: this.venueCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/venue-default?id=${res.data.id}&cid=&column_id=${this.venueCid}`
            );
          } else {
            this.$router.push(
              `/venue-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.venueCid}`
            );
          }
        }
      });
    },
    //获取首页志愿团队列表
    getVolunteerList() {
      let params = {
        aid: this.aid,
        page: 1,
        page_size: 3,
        column_id: this.volunteerCid,
      };

      voluteerList(params).then((res) => {
        if (res.code == 0) {
          this.volunteerList = res.data.datalist;
          this.loading = false;
        }
      });
    },
    //更多志愿团队
    moreVolunteer() {
      navinfo({ column_id: this.volunteerCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            this.$router.push(
              `/volunteerTeam-default?id=${res.data.id}&cid=&column_id=${this.volunteerCid}`
            );
          } else {
            this.$router.push(
              `/volunteerTeam-default?id=${res.data.pid}&cid=${res.data.id}&column_id=${this.volunteerCid}`
            );
          }
        }
      });
    },
    //跳转志愿团队详情
    goVolunteer(item) {
      navinfo({ column_id: this.volunteerCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.id}&cid=&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/volunteerTeam-default/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: this.volunteerCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            // this.$router.push(
            //   `/active-default/detail?tid=${item.id}&id=${res.data.pid}&cid=${res.data.id}&column_id=${this.activeCid}`
            // );
            let routeUrl = this.$router.resolve({
              path: `/volunteerTeam-default/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: this.volunteerCid,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
    },
    //获取首页资讯公告列表
    getArticleList() {
      let params = {
        aid: this.aid,
        // page: 1,
        // page_size: 6,
        // app_type: 1,
        // column_id: this.articleCid,
      };
    recommend_article(params).then((res)=>{
      this.articleLoading = false;
        if (res.code == 0) {
          res.data.list.forEach((item) => {
            item.publish_time = moment(item.publish_time * 1000).format(
              "YYYY-MM-DD"
            );
          });
          this.articleList = res.data.list;
        }
    })
      // articleList(params).then((res) => {
      //   console.log(res);
      //   this.articleLoading = false;
      //   if (res.code == 0) {
      //     res.data.list.forEach((item) => {
      //       item.publish_time = moment(item.publish_time * 1000).format(
      //         "YYYY-MM-DD"
      //       );
      //     });
      //     this.articleList = res.data.list;
      //   }
      // });
    },
    //跳转资讯详情
    goNewsDetail(item) {
      if(item.is_link==1){
          window.open(item.jump_url, "_blank");
      }else{
        navinfo({ column_id: item.column_id }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            let routeUrl = this.$router.resolve({
              path: `/${item.tpl_code}/detail`,
              query: {
                tid: item.id,
                id: res.data.id,
                cid: "",
                column_id: item.column_id,
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            let routeUrl = this.$router.resolve({
              path: `/${item.tpl_code}/detail`,
              query: {
                tid: item.id,
                id: res.data.pid,
                cid: res.data.id,
                column_id: item.column_id,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      });
      }

    },
    goNews() {
      navinfo({ column_id: this.articleCid }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            if (res.data.content_id == 0) {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}`,
                query: {
                  id: res.data.id,
                  cid: "",
                  column_id: this.articleCid,
                },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}/detail`,
                query: {
                  tid: res.data.content_id,
                  id: res.data.id,
                  cid: "",
                  column_id: this.articleCid,
                },
              });
              window.open(routeUrl.href, "_blank");
            }
          } else {
            if (res.data.content_id == 0) {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}`,
                query: {
                  id: res.data.pid,
                  cid: res.data.id,
                  column_id: this.articleCid,
                },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}/detail`,
                query: {
                  tid: res.data.content_id,
                  id: res.data.pid,
                  cid: res.data.id,
                  column_id: this.articleCid,
                },
              });
              window.open(routeUrl.href, "_blank");
            }
          }
        }
      });
    },
    goNewsLeft() {
      navinfo({ column_id: this.articleCid1 }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            if (res.data.content_id == 0) {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}`,
                query: {
                  id: res.data.id,
                  cid: "",
                  column_id: this.articleCid1,
                },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}/detail`,
                query: {
                  tid: res.data.content_id,
                  id: res.data.id,
                  cid: "",
                  column_id: this.articleCid1,
                },
              });
              window.open(routeUrl.href, "_blank");
            }
          } else {
            if (res.data.content_id == 0) {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}`,
                query: {
                  id: res.data.pid,
                  cid: res.data.id,
                  column_id: this.articleCid1,
                },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}/detail`,
                query: {
                  tid: res.data.content_id,
                  id: res.data.pid,
                  cid: res.data.id,
                  column_id: this.articleCid1,
                },
              });
              window.open(routeUrl.href, "_blank");
            }
          }
        }
      });
    },
    goNewsRight() {
      navinfo({ column_id: this.articleCid2 }).then((res) => {
        if (res.code == 0) {
          if (res.data.pid == 0) {
            if (res.data.content_id == 0) {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}`,
                query: {
                  id: res.data.id,
                  cid: "",
                  column_id: this.articleCid2,
                },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code2}/detail`,
                query: {
                  tid: res.data.content_id,
                  id: res.data.id,
                  cid: "",
                  column_id: this.articleCid2,
                },
              });
              window.open(routeUrl.href, "_blank");
            }
          } else {
            if (res.data.content_id == 0) {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}`,
                query: {
                  id: res.data.pid,
                  cid: res.data.id,
                  column_id: this.articleCid2,
                },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              let routeUrl = this.$router.resolve({
                path: `/${res.data.tpl_code}/detail`,
                query: {
                  tid: res.data.content_id,
                  id: res.data.pid,
                  cid: res.data.id,
                  column_id: this.articleCid2,
                },
              });
              window.open(routeUrl.href, "_blank");
            }
          }
        }
      });
    },
    // //文化资讯
    // getArticleList(cat_id) {
    //   let params = {
    //     aid: this.aid,
    //     cat_id: this.cutural_id,
    //     page_size: 6,
    //     page: 1,
    //     app_type: 1,
    //     search_sub_cat: 0,
    //   };
    //   articleList(params).then((res) => {
    //     console.log(res);
    //     this.articleLoading = false;
    //     if (res.code == 0) {
    //       res.data.list.forEach((item) => {
    //         item.publish_time = moment(item.publish_time * 1000).format(
    //           "YYYY-MM-DD"
    //         );
    //       });
    //       this.article_list = res.data.list;
    //     }
    //   });
    // },
    // //文化活动 读取最新3条
    // getActList() {
    //   var now = moment().unix();
    //   getActivityList({ aid: this.aid, page: 1, page_size: 3 }).then((res) => {
    //     if (res.code == 0) {
    //       let { datalist } = res.data;
    //       let rows = [];
    //       for (let i = 0; i < datalist.length; i++) {
    //         let start_at = moment(
    //           datalist[i].active_start_time,
    //           "YYYY-MM-DD hh:mm"
    //         ).unix();
    //         let end_at = moment(
    //           datalist[i].active_end_time,
    //           "YYYY-MM-DD hh:mm"
    //         ).unix();
    //         let status;
    //         if (end_at < now) {
    //           status = 3; //已结束
    //         } else if (now < end_at && now > start_at) {
    //           status = 2; //进行中
    //         } else {
    //           status = 1; //预约中
    //         }
    //         let row = Object.assign({}, datalist[i], { status: status });
    //         rows.push(row);
    //       }
    //       this.activity_List = rows;
    //     }

    //     this.actLoading = false;
    //   });
    // },
    // //文化培训读取3条
    // getCourseList() {
    //   courseList({ aid: this.aid, page: 1, page_size: 3 }).then((res) => {
    //     if (res.code == 0) {
    //       let { datalist } = res.data;
    //       this.chapter_list = datalist;
    //       this.Course_cat_id = this.chapter_list[0].category_id

    //       console.log(this.Course_cat_id)
    //     }
    //     this.getNavList()
    //     this.courseLoading = false;
    //   });
    // },
    // // 文华直播 读取3跳
    // getliveList() {
    //   queryLiveList({ aid: this.aid, page: 1, page_size: 3, type: 0 }).then(
    //     (res) => {
    //       console.log(res);
    //       var list = res.data.datalist;
    //       var regex = /(<([^>]+)>)/gi;
    //       if (list.length != 0) {
    //         list.forEach((item) => {
    //           item.content = item.content.replace(regex, "");
    //         });
    //       }
    //       this.live_list = res.data.datalist;
    //     }
    //   );
    // },
    // //非遗文化读取最新一条
    // getCultureOne() {
    //   cultureList({
    //     aid: this.aid,
    //     page: 1,
    //     limit: 3,
    //     class_id: this.class_id,
    //   }).then((res) => {
    //     console.log(res)
    //     if (res.code == 0) {
    //       let { datalist } = res.data;
    //       this.intangible_list = datalist;
    //     }
    //     this.cultureLoading = false;
    //   });
    // },
    // // toIntangibleDetail(id) {
    // //   this.$router.push({ path: "/intangible/detail", query: { id: id } });
    // // },
    // // 获取场馆列表
    // getStadiumList() {
    //   getStadium({
    //     aid: this.aid,
    //     latitude: "112.191751",
    //     longitude: "31.017177",
    //   }).then((res) => {
    //     console.log(res);
    //     if (res.code == 0) {
    //       this.stadiumList = res.data.datalist;
    //     }
    //   });
    // },
    // fyPrev() {
    //   if (this.intangible_active - 1 < 0) {
    //     this.intangible_active = 0;
    //   } else {
    //     this.intangible_active -= 1;
    //   }
    // },
    // fyNext() {
    //   let max = this.intangible_list.length;
    //   if (this.intangible_active + 1 == max) {
    //     this.intangible_active = max - 1;
    //   } else {
    //     this.intangible_active += 1;
    //   }
    // },
    handleClick(i) {},
    // // 获取志愿服务列表
    // getVolunteerList() {
    //   let self = this;
    //   voluteerList(self.listQuery)
    //     .then((res) => {
    //       if (res.code == 0) {
    //         this.rows = res.data.datalist;
    //         self.loading = false;
    //       }
    //     })
    //     .catch(() => {
    //       self.loading = false;
    //     });
    // },
    // getConf() {
    //   getConf({ aid: this.aid }).then((res) => {
    //     var libModule = res.data;
    //     console.log(libModule);
    //     libModule.map((item) => {
    //       if (item.code == "culture_home") {
    //         var CULTURE_INDEX_ARTICLE_CATS =
    //           item.items.CULTURE_INDEX_ARTICLE_CATS;

    //         var CULTURE_INDEX_ARTICLE_CATS = JSON.parse(
    //           CULTURE_INDEX_ARTICLE_CATS
    //         );
    //         console.log(CULTURE_INDEX_ARTICLE_CATS);
    //         var array = CULTURE_INDEX_ARTICLE_CATS;
    //         this.appreciation_id = array[1].left[0];
    //         this.exhibit_id = array[2].left[0];
    //         this.exhibit_cat_id = array[2].left[1];
    //         this.dynamic_id = array[0].left[0];
    //         this.cutural_id = array[0].right[0];
    //         this.notice_id = array[0].right[1];
    //         this.getAppreciationList(this.appreciation_id);
    //         this.getExhibitList(this.exhibit_cat_id);
    //         this.getArticleList(this.dynamic_id);
    //       }
    //     });
    //   });
    // },
    // getAppreciationList(appreciation_id) {
    //   getArticleList({
    //     aid: this.aid,
    //     app_type: 1,
    //     cat_id: appreciation_id,
    //     search_sub_cat: 1,
    //     page_size: 3,
    //   }).then((res) => {
    //     console.log(res)
    //     this.appreciation_list = res.data.list;
    //   });
    // },
    // getExhibitList(exhibit_id) {
    //   console.log(exhibit_id);
    //   cultureList({
    //     aid: this.aid,
    //     class_id: 1,
    //     category_id: exhibit_id,
    //     limit: 3,
    //     get_children_list:1
    //   }).then((res) => {
    //     this.exhibit_list = res.data.datalist;
    //     console.log(this.exhibit_list)
    //   });
    // },
    // go_detail(item) {
    //   console.log(item)
    //   if (item.is_link == 1) {
    //     window.open(item.jump_url);
    //   } else {
    //     this.$router.push({path:'/artistic/detail',query:{name:this.artistic_name,pid:this.artistic_pid,navId:this.artistic_nav_id,id:item.id}});

    //     // let routeUrl = this.$router.resolve({
    //     //   path: `/artistic/detail`,
    //     //   query: { id: item.id,pid: },
    //     // });
    //     // window.open(routeUrl.href, "_blank");
    //   }
    // },
    handleServe() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/venue/venueSpace/detail/detail?id=" +
          this.venueList[this.current].id +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      this.dialogVisible = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
    getCode() {
      let params = {
        wxapp_name: "culture",
        page: "bookgo/culture/homepage/index?aid=" + process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.wxprogram_code = pic;
      });
    },
    // toDetail(item) {
    //   if (item.is_link == 1 && item.jump_url) {
    //     window.open(item.jump_url);
    //   } else {
    //     let routeUrl = this.$router.resolve({
    //       path: `/news/detail`,
    //       query: { id: item.id },
    //     });
    //     window.open(routeUrl.href, "_blank");
    //   }
    // },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/homepage.scss";
</style>
